import { AxiosInstance, AxiosResponse } from 'axios'
import {
  convertLabelColorConfig,
  convertSocialLink,
} from '../deserializers/common'
import { GlobalConfig } from '~/types/common'

export default function (instance: AxiosInstance) {
  const base = 'api/aspos/common'

  return {
    async getConfig(): Promise<GlobalConfig | null> {
      const response: AxiosResponse = await instance.get(`/${base}/config`)
      if (!response.data.Success) return null

      const config = response.data.Config

      const result: GlobalConfig = {
        gtmEnabled: config.GtmEnabled,
        gtmSetting: config.GtmSetting,

        webStoreId: config.WebshopStoreId,
        deliveryCutoffLarge: config.DeliveryCutoffLarge,
        deliveryCutoffSmall: config.DeliveryCutoffSmall,
        pickupCutoff: config.PickupOff,
        socialLinks: config.SocialLinks?.map((item: any) =>
          convertSocialLink(item)
        ),
        specialWebNodes: {
          actie: config.SpecialWebNodes?.Actie?.[0],
          'meer=minder': config.SpecialWebNodes?.['Meer=Minder']?.[0],
          'op=op': config.SpecialWebNodes?.['Op=Op']?.[0],
          homePopular: config.SpecialWebNodes?.HomePopular?.[0],
        },
        shippingRegions: config.ShippingRegions,
        minimumFreeShippingLarge: config.MinimumFreeShippingLarge,
        minimumFreeShippingSmall: config.MinimumFreeShippingSmall,
        loyaltyCustomerCodes: config.CustomerGroups?.Loyalty,
        hideStoreNames: config.HideStores?.map((name: any) =>
          name.toLowerCase()
        ),
        stockCount: config.StockCount || 0,
        minimumPointsToRedeem: config.MinimumPointsToRedeem || 0,
        loyaltyCard: {
          optInNewCustomersByDefault:
            config.LoyaltyCard?.OptInNewCustomersByDefault,
        },
        freeDeliveryLine: config.Shipping?.FreeDeliveryLine,
      }

      if (config.DiscountBackgroundColors) {
        result.discountBackgroundColors = Object.entries(
          config.DiscountBackgroundColors
        ).reduce((result, entry) => {
          result[entry[0].toLowerCase()] = entry[1] as string
          return result
        }, {})
      }

      if (config.ProductSpecifications) {
        result.productSpecifications = Object.entries(
          config.ProductSpecifications
        ).reduce((result, entry) => {
          result[entry[0].toLowerCase()] = entry[1] as string
          return result
        }, {})
      }

      if (config.Holidays?.length) {
        result.holidays = config.Holidays.map((date: any) => new Date(date))
      }

      if (config.DiscountLabels) {
        result.discountLabelColors = convertLabelColorConfig(
          config.DiscountLabels
        )
      }

      if (config.ProductLabels) {
        result.productLabelColors = convertLabelColorConfig(
          config.ProductLabels
        )
      }

      if (config.DeliveryCountries) {
        result.deliveryCountries = config.DeliveryCountries
      }

      return result
    },
  }
}
