import { AxiosInstance, AxiosResponse } from 'axios'
import { convertBrand, convertBrands, convertOTBrands, convertOTBrand } from '~/lib/api/deserializers/brand'

export default function (instance: AxiosInstance) {
  const base = '/api/aspos/brands'

  return {
    // async getBrands(): Promise<any> {
    //   const response: AxiosResponse = await instance.get(`${base}`)

    //   if (!response.data.Success) return []
    //   return convertBrands(response.data?.Brands).sort((a, b) =>
    //     a.name > b.name ? 1 : -1
    //   )
    // },
    async getBrands(): Promise<any> {
      const response: AxiosResponse = await instance.post("/api/vm/u", {
        
          Url: "home",
          AtomType: "Content.Article",
          BrandCategories: "mainbrands",
          Culture: "nl-NL"
        
      })

      if (!response.data.Success) return []
      return convertOTBrands(response.data?.Article?.BrandCategories[0]?.Brands).sort((a, b) =>
        a.name > b.name ? 1 : -1
      )
    },

    async getPopularBrands(limit = 10): Promise<any> {
      const response: AxiosResponse = await instance.get(`${base}/popular`, {
        params: {
          limit,
        },
      })

      if (!response.data.Success) return []
      return convertBrands(response.data?.Brands)
    },

    async getBrandByUrl(url: string): Promise<any> {
      const response: AxiosResponse = await instance.get(
        `${base}/url/${encodeURIComponent(url)}`
      )
      if (!response.data.Success) return null

      return convertBrand(response.data?.Brand)
    },
  }
}
